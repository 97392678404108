<template>
  <div class="church-list template-1">
    <header class="page-header container">
      <h1>{{ translations.tcTestimonySpeakers }}</h1>
    </header>
    <!-- / Page Header -->

    <camp-selector @camp_changed="page_load()" :showCamp="false" :tooltip="translations.tcCurrentHome"
      :i18n="translations.components"></camp-selector>
    <!-- / Camp Selector -->

    <data-table :fields="fields" :listType="list_type" :items="testimonySpeakersTranslated" :addItems="addItems[0]"
      :addItemsNonMember="addItems[1]" :toggleMe="true" :handleDeleteAction="allow_delete_action"
      :handleEditAction="false" :handleViewAction="false" @handleDeleteClick="handleDeleteClick($event)"
      :csvUrl="csvUrl" :pdfUrl="pdfUrl" :reportName="reportName" @addSpeaker="addSpeaker" @addNonMember="addNonMember"
      :i18n="translations.components"></data-table>
    <!-- / Data Tables -->

    <footer class="page-footer"></footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTableTestimony.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'testimony-speakers',
  mixins: [translationMixin],
  data() {
    return {
      secured_controls: {
        testimony_add_edit_delete_button: '32bc1eaa-8376-4375-a6ef-6cabaca13f63',
      },
      allow_delete_action: false,
      translations: {},
      list_type: 'camp_member_churches',
      fields: [
        { key: 'sort_name', label: 'Name', sortable: true },
        { key: 'adr_city', label: 'City', sortable: true },
        { key: 'adr_state', label: 'State', sortable: true },
        { key: 'testimony_type', label: 'Type', sortable: true },
        { key: 'is_member_flag', label: 'Member', sortable: true },
        { key: 'actions', sortable: false, tdClass: 'cwidth' },
      ],
      hiddenItems: {
        display: false,
      },
      addItems: [
        {
          display: false,
          text: 'Add Member', // Add Visit
          action: 'addSpeaker',
        },
        {
          display: false,
          text: 'Add Non Member', // Add Visit
          action: 'addNonMember',
        },
      ],
      reportName: 'TestimonySpeakersExport',
      testimonySpeakersTranslated: [],
    }
  },
  methods: {
    ...mapActions({
      clearSearchValues: 'userModule/clearSearchValues',
      getMemberChurches: 'campModule/getMemberChurches',
      getSearchValue: 'userModule/setSearchValues',
      getTestimonySpeakers: 'directory/getTestimonySpeakers',
      loadChurches: 'churchMinistry/loadChurches',
      setLoadingStatus: 'menu/setLoadingStatus',
      upsertTestimonySpeaker: 'directory/upsertTestimonySpeaker',
      loadTestimonySpeakersUrls: 'directory/loadTestimonySpeakersUrls',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await this.getTestimonySpeakers(this.officerSelectStatekey.country_state)
        await this.loadTestimonySpeakersUrls(this.officerSelectStatekey.country_state)
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async handleDeleteClick(evt) {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: this.translations.tcTestimonyWillBeDeleted,
        confirmButtonText: this.translations.tcOk || 'Ok',
        cancelButtonText: this.translations.tcCancel || 'Cancel',
      }).then((result) => {
        if (result.value) {
          this.handleDeleteRequest(evt)
        }
      })
    },
    async handleDeleteRequest(data) {
      let requestData = {
        ixo_key: data.item.ixo_key,
        ixa_key: data.item.ixa_key,
        tst_key: data.item.ixa_tst_key,
        acr_ind_key: data.item.ind_key,
        start_date: data.item.ixa_start_date || new Date().toDateString(),
        org_key: this.officerSelectStatekey.country_state,
        delete: true,
        user_ind_key: this.userId,
      }
      await this.upsertTestimonySpeaker(requestData)
        .then(async () => {
          await this.page_load().then(() => {
            this.translateTestimonySpeakers()
          })
        })
    },
    addSpeaker() {
      this.getSearchValue({ member: 0, nonMember: 1 })
      this.$router.push({ name: 'add-testimony-speaker' })
    },
    addNonMember() {
      this.getSearchValue({ member: 1, nonMember: 0 })
      this.$router.push({ name: 'add-testimony-speaker' })
    },
    loadTranslations() {
      this.addItems[0].text = this.translations.tcAddMember
      this.addItems[1].text = this.translations.tcAddNonMember

      this.fields[0].label = this.translations.tcName
      this.fields[1].label = this.translations.tcCity
      this.fields[2].label = this.translations.tcState
      this.fields[3].label = this.translations.tcType
      this.fields[4].label = this.translations.tcMember
      this.fields[5].label = this.translations.tcActions

      this.addItems.map((ai) => {
        ai.display = this.iCanSee(this.secured_controls.testimony_add_edit_delete_button)
      })
      this.allow_delete_action = this.iCanSee(this.secured_controls.testimony_add_edit_delete_button)
    },
    translateTestimonySpeakers() {
      if (!this.testimonySpeakers) return false

      this.testimonySpeakersTranslated = this.testimonySpeakers.map((speaker) => {
        const isMember = speaker.is_member_flag === 'Yes' ? this.translations.tcYes : this.translations.tcNo
        const testimonyGuid = this.translations.common['testimony-type'].hasOwnProperty(speaker.ixa_tst_key)
          ? speaker.ixa_tst_key
          : ''
        const testimonyText = testimonyGuid
          ? this.translations.common['testimony-type'][testimonyGuid]
          : speaker.testimony_type

        return { ...speaker, is_member_flag: isMember, testimony_type: testimonyText }
      })
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await this.clearSearchValues()
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('camp-select', 'data-table-member-churches'),
        this.getComponentTranslations('common.testimony-type'),
      ]).then(async (results) => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common },
        }
        this.$set(this.translations, 'components', translatedText)
        this.loadTranslations()
        await this.page_load().then(() => {
          this.translateTestimonySpeakers()
        })
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      csvUrl: 'directory/testimonySpeakersCSVUrl',
      held_visits_by_camp: 'churchMinistry/held_visits_by_camp',
      iCanSee: 'user/iCanSee',
      officerSelectStatekey: 'user/officerToolbarSelected',
      pdfUrl: 'card/restockingVisitPDFUrl',
      prefCulture: 'user/userPreferredCulture',
      testimonySpeakers: 'directory/testimonySpeakers',
      userCampKey: 'user/userCampKey',
      userId: 'user/userId',
      userOrgKey: 'user/userOrgKey',
      userStateKey: 'user/userStateKey',
    }),
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';

.cwidth {
  min-width: 252px !important;
}
</style>
